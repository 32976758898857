import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './header';
import Footer from './footer';

import * as styles from "./layout.module.scss"

const Layout = ({ children }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'space-between' }}>
      <Helmet>
        <script src="https://kit.fontawesome.com/9f95e6fa73.js" crossorigin="anonymous"></script>
      </Helmet>
      <div>
        <Header />
        <div className={styles.wrapper}>
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;