import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import IndexFilter from "../components/indexFilter";

import Seo from "../utils/seo";

import * as styles from "./index.module.scss";

const Index = ({ data: { site } }) => {

  const metaDescription =
    "Uw vastgoedmakelaar voor de regio turnhout. Bruyninckx Vastgoed begeleidt u bij de aankoop of verkoop van uw woning. Gratis schatting. Succes verzekerd.";

  const breadcrumbSchema = `{
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": "1",
        "name": "Bruyninckx Vastgoed",
        "item": "${site.siteMetadata.siteUrl}"
      }
    ]
  }`;

  return (
    <Layout>
      <Seo title="Home" description={metaDescription} />
      <Helmet>
        <script type="application/ld+json">{breadcrumbSchema}</script>
      </Helmet>
      <article className={styles.wrapper}>
        <section className={`${styles.section} ${styles.hero}`}>
          <h1>Uw immo partner voor regio Turnhout</h1>
          <IndexFilter className={styles.filter} />
        </section>
      </article>
    </Layout>
  );
};

export default Index;

export const data = graphql`
  {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
