// extracted by mini-css-extract-plugin
export var advantage = "index-module--advantage--bd7a9";
export var advantages = "index-module--advantages--7a4d2";
export var featured = "index-module--featured--01da0";
export var featuredWrapper = "index-module--featured-wrapper--76d14";
export var filter = "index-module--filter--93893";
export var hero = "index-module--hero--8a08f";
export var movingIsEasy = "index-module--moving-is-easy--a3e6d";
export var news = "index-module--news--442c1";
export var newsItem = "index-module--news-item--78fdb";
export var newsWrapper = "index-module--news-wrapper--b6b9f";
export var ourAdvantages = "index-module--our-advantages--cf1a2";
export var section = "index-module--section--4a401";
export var testimonials = "index-module--testimonials--0d968";
export var title = "index-module--title--56ee9";
export var wrapper = "index-module--wrapper--8d8a2";