import React from "react";
import { Link } from "gatsby";

import * as styles from "./header.module.scss";
import logo from "../images/logo.svg";
import { Desktop, Mobile, Tablet } from "../utils/breakpoint";

const Header = () => {
  return (
    <header>
      <Desktop>
        <nav className={styles.topNav}>
          <Link to="/">
            <img className={styles.logo} src={logo} width="160" alt="logo" />
            <span className={styles.slogan}>Succes verzekerd</span>
          </Link>
        </nav>
      </Desktop>
      <Mobile>
        <nav className={styles.topNav}>
          <Link to="/">
            <img className={styles.logo} src={logo} width="140" alt="logo" />
          </Link>
        </nav>
      </Mobile>
      <Tablet>
        <nav className={styles.topNav}>
          <Link to="/home">
            <img className={styles.logo} src={logo} width="140" alt="logo" />
          </Link>
        </nav>
      </Tablet>
    </header>
  );
};

export default Header;
