import React from 'react'
import { css } from '@emotion/core';

const IndexFilter = ({ ...props }) => {
  return (
    <div css={[filterWrapperCss]} {...props}>
      <div css={[modeRowCss]}>
        <p>Voor meer informatie neemt u best telefonisch contact op met ons kantoor.</p>
        <p><a css={[modeRowCss]} href="tel:014 41 69 06">014 41 69 06</a></p>
      </div>
    </div>
  )
}

export default IndexFilter;

const filterWrapperCss = css`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  min-width: 700px;
  @media (max-width: 767px) {
    min-width: unset
  }
`

const modeRowCss = css`
  color: white;
`