import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ description, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const localBusinessScript = `{
    "@context": "https://schema.org/",
    "@type": "LocalBusiness",
    "@id": "${site.siteMetadata.siteUrl}",
    "name": "Bruyninckx Vastgoed",
    "slogan": "Succes verzekerd",
    "address": {
      "@type": "PostalAddress",
      "postalCode": "2300",
      "streetAddress": "Rubensstraat 141",
      "addressCountry": "België",
      "addressLocality": "Turnhout",
      "telephone": "014 41 69 06"
    },
    "image": "https://socialkickcms.be/uploads/bruyninckx/originals/0fd0d412-bd29-4c6d-a71d-98b964d5948d.svg",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "opens": "09:30:00",
        "closes": "12:00:00",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]
      },
      {
        "@type": "OpeningHoursSpecification",
        "opens": "13:30:00",
        "closes": "17:00:00",
        "dayOfWeek": ["Monday", "Tuesday", "Thursday"]
      },
  ],
    "telephone": "014 41 69 06",
    "url": "${site.siteMetadata.siteUrl}"
  }`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `immo-website`,
        },
        {
          name: `facebook-domain-verification`,
          content: `bh6u8kkyen0njf09sjumw8u7jdg4i5`
        }
      ].concat(meta)}
    >
      <script type="application/ld+json">{localBusinessScript}</script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `nl`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  // title: PropTypes.string.isRequired,
}

export default Seo;
